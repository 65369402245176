import React from "react"

import Layout from "~/components/common/Layout"
import { getBackLink, getHeaderTitles, getNextLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"
import { graphql, useStaticQuery } from "gatsby"
import HeroVideo from "~/components/page/HeroVideo"
import { InfoboxDetailed } from "~/components/page/Infobox"
import Copyright from "~/components/common/Copyright"
import { css } from "@emotion/core"
import GraphicWithText from "~/components/page/GraphicWithText"
import { Heading, Text } from "~/components/common/Typography"
import { Box, Flex } from "reflexbox"
import { mq } from "~/components/common/theme"
import Source from "~/components/common/Source"

const Page = ({ myPage = 5, mySubpage = 1 }) => {
  const data = useStaticQuery(graphql`
      query {
          hero: file(name: { eq: "6.2HERO" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic1: file(name: { eq: "6.2a-ADIDAS-happyfaces" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic1Mobile: file(name: { eq: "6.2a-ADIDAS-happyfaces-mobile" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic2: file(name: { eq: "6.2b-ADIDAS-illness" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic3: file(name: { eq: "6.2f-ADIDAS-stronger" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic4: file(name: { eq: "6.3c-ADIDAS-bodyesteem" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic5: file(name: { eq: "6.3d-ADIDAS-risk" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic6: file(name: { eq: "6.3e-ADIDAS-symbol" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic7: file(name: { eq: "6.3f-ADIDAS-nosmoking" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `)

  const {
    hero,
    graphic1,
    graphic1Mobile,
    graphic2,
    graphic3,
    graphic4,
    graphic5,
    graphic6,
    graphic7,
  } = data

  const graphicsData = [
    {
      image: graphic1,
      imageMobile: graphic1Mobile,
      title: "Better self-reported health",
      Text: () => (
        <span>
          Across grade levels, a higher percentage of female athletes described
          their health as “excellent” compared to non-athletes.
          <Source>45</Source>
        </span>
      ),
    },
    {
      image: graphic2,
      title: "Fewer chronic illnesses",
      Text: () => (
        <span>
          Girls who play sports have stronger immune systems and a reduced risk
          of chronic illnesses.<Source>46</Source>
        </span>
      ),
    },
    {
      image: graphic3,
      title: "Higher body esteem",
      Text: () => (
        <span>
          There is a positive relationship between athletics and body esteem
          among girls. Girls who play sports are also 16% less likely to want to
          change their image.<Source>47</Source>
        </span>
      ),
    },
    {
      image: graphic4,
      title: "Reduced risk of obesity",
      Text: () => (
        <span>
          Girls who play sports are less obsese than non-athletes.
          <Source>48</Source>
        </span>
      ),
    },
    {
      image: graphic5,
      title: "Healthier menstruation",
      Text: () => (
        <span>
          Girls who play sports have lighter and more regular periods. They also
          tend to experience less cramping and discomfort.<Source>49</Source>
        </span>
      ),
    },
    {
      image: graphic6,
      title: "Stronger bones",
      Text: () => (
        <span>
          Girls who participate in sports have stronger bones and a reduced risk
          of osteoporosis.<Source>50</Source> As written in the Guardian,
          studies “have found that bones retain a ‘memory’ of early-life
          exercise before the onset of puberty that lasts long after the
          exercise has ceased and provides health benefits even in a relatively
          sedentary adulthood.”<Source>51</Source>
        </span>
      ),
    },
    {
      image: graphic7,
      title: "Reduced cigarette and illicit drug use",
      Text: () => (
        <span>
          National studies found that girls who play sports are 92% less likely
          to use drugs.<Source>52</Source>
        </span>
      ),
    },
  ]

  const graphicMq = mq({
    width: ["70%", "70%", "80%"],
  })

  const mqMobile = mq({
    display: ["flex", "none"],
  })
  const mqNonMobile = mq({
    display: ["none", "flex"],
  })

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge="Participating in sports is good for physical health. But take a look at other specific ways it can benefit a girl’s health as she is participating."
        image={hero}
        bg={"white"}
      />

      <InfoboxDetailed>
        <Flex
          maxWidth={"994px"}
          mx={"auto"}
          justifyContent={"center"}
          alignItems={"start"}
          css={css`
            flex-wrap: wrap;
          `}
        >
          {graphicsData.map((item, index) => (
            <>
              <GraphicWithText
                mq={graphicMq}
                image={item.image}
                width={["100%", "50%"]}
                key={index}
                px={6}
                css={item.imageMobile ? mqNonMobile : css``}
              >
                <Box fontSize={["16px", "16px"]} lineHeight={["18px", "21px"]}>
                  <Heading
                    uppercase
                    as="h3"
                    fontFamily={"AdineuePROBoldWeb"}
                    fontSize={["16px", "16px", "24px"]}
                    lineHeight={["18px", "21px", "31px"]}
                  >
                    {item.title}
                  </Heading>
                  <Text mt={0}>{item.Text && <item.Text></item.Text>}</Text>
                </Box>
              </GraphicWithText>

              {item.imageMobile && (
                <GraphicWithText
                  mq={graphicMq}
                  image={item.imageMobile}
                  width={["100%", "50%"]}
                  key={index}
                  px={6}
                  css={mqMobile}
                >
                  <Box
                    fontSize={["16px", "16px"]}
                    lineHeight={["18px", "21px"]}
                  >
                    <Heading
                      uppercase
                      as="h3"
                      fontFamily={"AdineuePROBoldWeb"}
                      fontSize={["16px", "16px", "24px"]}
                      lineHeight={["18px", "21px", "31px"]}
                    >
                      {item.title}
                    </Heading>
                    <Text mt={0}>{item.Text && <item.Text></item.Text>}</Text>
                  </Box>
                </GraphicWithText>
              )}
            </>
          ))}
        </Flex>
      </InfoboxDetailed>

      <Copyright/>
    </Layout>
  )
}

export default withProgress(Page, 5, 1)
